/**
 * Connection that wraps a socket and provides an interface to interact
 */
import type { Auth } from "./auth";

export type ConnectionOptions = {
  auth?: Auth;
};

export type ConnectionEventListener = (
  conn: Connection,
  eventData?: any
) => void;

type Events = "ready" | "disconnected" | "reconnect-error";

export class Connection {
  options: ConnectionOptions;

  eventListeners: Map<string, ConnectionEventListener[]>;

  suspendReconnectPromise?: Promise<void>;

  constructor(options: ConnectionOptions) {
    // connection options
    this.options = options;
    // map of event listeners
    this.eventListeners = new Map();
  }

  get connected() {
    // Using conn.socket.OPEN instead of WebSocket for better node support
    return true;
  }

  addEventListener(eventType: Events, callback: ConnectionEventListener) {
    let listeners = this.eventListeners.get(eventType);

    if (!listeners) {
      listeners = [];
      this.eventListeners.set(eventType, listeners);
    }

    listeners.push(callback);
  }

  removeEventListener(eventType: Events, callback: ConnectionEventListener) {
    const listeners = this.eventListeners.get(eventType);

    if (!listeners) {
      return;
    }

    const index = listeners.indexOf(callback);

    if (index !== -1) {
      listeners.splice(index, 1);
    }
  }

  suspendReconnectUntil(suspendPromise: Promise<void>) {
    this.suspendReconnectPromise = suspendPromise;
  }

  suspend() {
    if (!this.suspendReconnectPromise) {
      throw new Error("Suspend promise not set");
    }
    // this.socket.close();
  }
}
