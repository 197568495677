// Compat needs to be first import
import "../resources/compatibility";
import { loadTokens, saveTokens } from "../common/auth/token_storage";
import { Auth, getAuth, tauiUrl } from "../data/auth";
import { fetchConfig } from "../data/config";
import { Connection } from "../data/connection";
import "../resources/array.flat.polyfill";
import "../resources/safari-14-attachshadow-patch";
import { TauiConfig } from "../types/config";
import { ERR_INVALID_AUTH } from "../types/errors";
import { supportsServiceWorker } from "../util/register-service-worker";
import { renderLaunchScreenLogo } from "../util/launch-screen";

declare global {
  interface Window {
    tauiConnection: Promise<{
      config: TauiConfig;
      auth: Auth;
      conn: Connection;
    }>;
    tauiConnectionReady?: (tauiConnection: Window["tauiConnection"]) => void;
  }
}

const configProm = async () => {
  // Fetch config
  let response;
  let config;

  try {
    // We prefetch this data on page load in authorize.html.template for modern builds
    response = await ((window as any).tauiConfig || fetchConfig(tauiUrl));

    if (!response.ok) {
      throw new Error(
        `Fail to fetch config: HTTP response status is ${response.status}`
      );
    }

    config = await response.json();

    if (config?.sentryConfig?.enabled && !__STANDALONE__) {
      import("@sentry/browser").then((Sentry) => {
        const serverErrorsRegex = new RegExp(
          `500 Internal Server Error|401 Unauthorized|403 Forbidden|404 Not Found|502 Bad Gateway|503 Service Unavailable`,
          "mi"
        );
        const ignoreErrors = [
          "TypeError: Failed to fetch",
          "TypeError: NetworkError when attempting to fetch resource.",
          "TypeError: Cancelled",
          "TypeError: cancelado",
          "Non-Error exception captured",
          "Non-Error promise rejection captured",
          "NotSupportedError",
          "/operation is not supported/",
          "/Loading chunk [d]+ failed/",
        ];

        Sentry.init({
          dsn: config.sentryConfig.dsn,
          environment: config.sentryConfig.environment,
          release: __SENTRY_RELEASE__,
          debug: __DEV__ || config.sentryConfig.debug,
          // We ignore Server Errors. We have to define here since TAUI
          // http client uses setTimeout to detect http call progress.
          // And when the call fails, it throws an exception inside that timeout
          // that bubbles up higher to the main TAUI's error handler.
          ignoreErrors: [serverErrorsRegex, ...ignoreErrors],
        });

        Sentry.configureScope((scope) => {
          scope.setTag("pipeline", __VERSION__);
        });
      });
    }

    const styleLinkIcon = <HTMLAnchorElement>(
      document!.querySelector("link[rel*='icon']")
    );
    styleLinkIcon!.href = `/static/images/favicon/${
      config?.skin ?? "tucanored"
    }/favicon.ico`;

    if (config?.skin) {
      renderLaunchScreenLogo(
        `/static/images/assets/${config!.skin}/${config!.skin}_logo.png`
      );
    }
  } catch (err) {
    // eslint-disable-next-line
    console.error("Error loading config", err);
  }

  return config;
};

const authProm = async (config: TauiConfig) =>
  getAuth({
    tauiUrl: config!.backendHttpApi,
    saveTokens,
    loadTokens: () => Promise.resolve(loadTokens()),
  });

const connProm = async (config, auth) => {
  try {
    const conn = new Connection({ auth });

    // Clear url if we have been able to establish a connection
    if (location.pathname && location.pathname.startsWith("/oidc/")) {
      history.replaceState(null, "", "/");
    }

    return { config, auth, conn };
  } catch (err) {
    if (err !== ERR_INVALID_AUTH) {
      throw err;
    }

    // Clear stored tokens.
    saveTokens(null);

    auth = await authProm(config);
    const conn = new Connection({ auth });
    return { config, auth, conn };
  }
};

if (__DEV__) {
  // Remove adoptedStyleSheets so style inspector works on shadow DOM.
  // @ts-ignore
  delete Document.prototype.adoptedStyleSheets;
  performance.mark("taui-start");
}

window.tauiConnection = (configProm() as Promise<TauiConfig>).then(
  (config: TauiConfig) =>
    (authProm(config) as Promise<Auth>).then((data: Auth) =>
      connProm(config, data)
    )
);

// This is set if app was somehow loaded before core.
if (window.tauiConnectionReady) {
  window.tauiConnectionReady(window.tauiConnection);
}

// Start fetching some of the data that we will need.
window.tauiConnection
  // eslint-disable-next-line no-empty-pattern
  .then(({}) => {})
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err);

    document.location!.href = `${tauiUrl}/${
      __STANDALONE__ ? "authorize.html" : "auth/authorize"
    }?error=Error`;
  });

window.addEventListener("error", (e) => {
  if (!__DEV__ && e.message === "ResizeObserver loop limit exceeded") {
    e.stopImmediatePropagation();
    e.stopPropagation();
    return;
  }

  // prompt user to confirm refresh
  if (/Loading chunk [\d]+ failed/.test(e.message)) {
    if (supportsServiceWorker()) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration) {
          registration.update();
        } else {
          location.reload();
        }
      });
    } else {
      location.reload();
    }
    return;
  }
  if (e.message.toString().indexOf("ChunkLoadError") > -1) {
    // eslint-disable-next-line no-console
    console.log("[ChunkLoadError] Reloading due to error");
    location.reload();
    return;
  }

  // eslint-disable-next-line no-console
  console.error("Error", {
    logger: `frontend.${
      __DEV__ ? "js_dev" : "js"
    }.${__BUILD__}.${__VERSION__.replace(".", "")}`,
    message: `${e.filename}:${e.lineno}:${e.colno} ${e.message}`,
  });
});
